<template>

    <div>
        <div v-if="registerToSchool">
            <br>
            <h4 class=" text-center">{{ schoolName }}</h4>
            <br>
        </div>

        <div class="col d-sm-flex justify-content-center">
            <div class="card register-box">
                <div class="card-body">
                    <h4 class="text-center">Registrazione</h4>
                    <hr>
                    <form v-on:submit="register" class="">

                        <b-form-group id="firstname" label="Nome" label-for="firstname" description="">
                            <b-form-input v-model="firstname" required></b-form-input>
                        </b-form-group>

                        <b-form-group id="lastname" label="Cognome" label-for="lastname" description="">
                            <b-form-input v-model="lastname" required></b-form-input>
                        </b-form-group>

                        <b-form-group id="email" label="Email" label-for="email" description="">
                            <b-form-input v-model="email" required></b-form-input>
                        </b-form-group>

                        <b-form-group id="password" label="Password" label-for="password" description="">
                            <b-form-input :type="(passwordVisible) ? 'text' : 'password'" v-model="password" required></b-form-input>
                            <b-icon icon="eye" class="toggle-password" v-if="!passwordVisible" v-on:click="togglePasswordView" />
                            <b-icon icon="eye-slash" class="toggle-password" v-if="passwordVisible" v-on:click="togglePasswordView" />
                        </b-form-group>

                        <b-form-group id="birthDate" label="Data di nascita" label-for="birthDate" description="">
                            <b-form-input type="date" v-model="birthDate"  required></b-form-input>
                        </b-form-group>


                        <b-form-checkbox
                            id="privacy"
                            v-model="privacy"
                            name="privacy">
                            Dichiaro di aver letto e accettato l'<a :href="privacyUrl" target="_blank">informativa sul trattamento dei dati</a>.
                        </b-form-checkbox>

                        <br>
                        <div class="form-group">
                            <button class="btn btn-success btn-block" type="submit">Registrati</button>
                        </div>
                    </form>

                    <br>
                    <div class="text-center">
                        <p>Oppure se sei già registrato puoi entrare con le tue credenziali</p>
                        <router-link to="/login" class="btn btn-outline-primary">Accedi</router-link>
                    </div>


                    <p class="text-danger text-center" v-if="errorMsg">{{ errorMsg }}</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import AuthDataService from "./AuthDataService";
import SchoolDataService from "@/components/school/SchoolDataService";
import {HomepageByRole} from "@/helpers/HomepageByRole"
import {ParseUrl} from "@/helpers/ParseUrl";
import ProductLicenseDataService from "@/components/product_license/ProductLicenseDataService";
const vueConfig = require("../../../vue.config.js");

export default {
    name: "register",
    data() {
        return {
            email: "",
            password: "",
            firstname: "",
            lastname: "",
            birthDate: "",
            privacy: null,
            schoolId: null,
            errorMsg: null,
            registerToSchool: false,
            schoolName: '',
            redirectUri: '',
            passwordVisible: false,
            privacyUrl: 'https://www.toccafondimultimedia.com/privacy/'
        };
    },
    methods: {
        register(e) {
            e.preventDefault()
            if (!this.privacy) {
                this.errorMsg = "Devi accettare le condizione della privacy."
                return;
            }
            let data = {
                email: this.email,
                password: this.password,
                firstname: this.firstname,
                lastname: this.lastname,
                birthDate: this.birthDate,
                schoolId: this.schoolId
            }
            AuthDataService.register(data)
                .then((response) => {
                    if (response.data.registered) {
                        AuthDataService.login({email: this.email, password: this.password, schoolId: this.schoolId})
                            .then((response) => {
                                let authUser = {};
                                authUser.data = response.data.user;
                                authUser.token = response.data.token;
                                window.localStorage.setItem('authUser', JSON.stringify(authUser));
                                this.$store.state.user = authUser.data;
                                this.$store.state.token = authUser.token;
                                this.$store.state.schoolId = authUser.data.schoolId;
                                this.$store.state.schoolName = authUser.data.schoolName;
                                this.$store.state.isLoggedIn = true;
                                let self = this;
                                this.$router.push('/').then(response =>{
                                    console.log('register: router.push(/)');
                                }).catch(e => {});
                            })
                            .catch((e) => {
                                this.$store.state.loading = false;
                                this.errorMsg = "C'è stato un errore nel login automatico"
                                console.log("Cannot log in:" + e.response.data.message);
                            });
                    }
                })
                .catch((e) => {
                    this.$store.state.loading = false;
                    this.errorMsg = e.response.data.message;
                    console.log("Cannot register:" + e);
                });
        },
        focusEmail() {
            this.$refs.email.focus();
        },
        togglePasswordView() {
            this.passwordVisible = !this.passwordVisible;
        }
    },
    mounted() {
        if (!this.$store.state.isLoggedIn) {
            const result = ParseUrl();
            this.schoolId = result.schoolId;
            this.redirectUri = result.redirectUri;

            if (result.redirectUri.includes('activate')) {
                // get product_license info
                const licenseCode = result.redirectUri.split('/')[2];
                if (licenseCode) ProductLicenseDataService.getSchoolIdByCode(licenseCode)
                    .then((response) => {
                        // pass schoolId
                        this.schoolId = response.data.schoolId;
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            }

            if (this.schoolId) { // se viene passato l'ID della scuola
                this.registerToSchool = true;
                SchoolDataService.getInfo(this.schoolId)
                    .then((response) => {
                        this.schoolName = response.data.name;
                    })
                    .catch((errors) => {
                        this.schoolId = null;
                        this.errorMsg = 'QUESTA SCUOLA NON ESISTE'
                        console.log(errors);
                    });
            }
        } else {
            const uri = HomepageByRole('student');
            this.$router.push(uri).then(response =>{
                self.$store.state.isLoggedIn = true;
            }).catch(e => {});
        }
    }
}
</script>

<style scoped>
@media only screen and (min-width: 993px) {
    .register-box {min-width: 650px; }
}
</style>
<style>
.toggle-password {
    cursor: pointer;
    position: relative;
    top: -30px;
    left: 94%;
}
</style>
